<template>
    <div class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
        <template v-if="search.length">
            <div class="tasks-list__no-results">No search results found</div>
        </template>
        <template v-else>
            <CIcon class="table__clear__icon" size="6xl" name="cilTask"/>
            <div class="table__clear__title">No tasks yet</div>
            <div class="table__clear__title-sub">All tasks you create will be displayed here</div>
        </template>
    </div>
</template>

<script>

export default {
    name: "NoTasks",
    props: {
        search: {
            type: String,
            default: ''
        },
    },
};
</script>

<style lang="scss" scoped>
.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}
</style>
