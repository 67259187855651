<template>
    <div class="tasks">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active> Tasks</CBreadcrumbItem>
        </teleport>

        <CCard>
            <CCardBody>
                <div class="tasks__filter mb-3">
                    <CRow class="gx-0 gx-lg-3 justify-content-start w-100">
                        <CCol class="GCFilterMaxWidth col-12 col-md-12 col-lg-7 col-xl-9 col-xxl-9">
                            <GCFilter component="Tasks" :except-column="exceptColumn"
                                      @search="(val) => onTimeoutSearch(() => fetchData(val), 'onSearch')"/>
                        </CCol>
                        <CCol
                            class="bulkAction col-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0">
                            <CDropdown :key="`${selectedTasks.length}`" color="primary" class="w-100">
                                <CDropdownToggle class="taskBulkAction" color="primary"
                                                 :disabled="!selectedTasks.length" variant="outline">
                                    Bulk Action
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem @click="handleEditTasks">
                                        Edit Properties
                                    </CDropdownItem>
                                    <CDropdownItem @click="eventRevokeTasks">
                                        Revoke
                                    </CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        </CCol>
                    </CRow>
                </div>
                <UpdateTasks v-if="editTasks" :tasks="selectedTasks" @close="closeEdit"/>
                <SmartTable :items="tasks" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                            selectable="id" :selected-items="selectedTasks" :loading="loadingTable"
                            :active-page="activePage" :per-page="perPage" :pages="pages"
                            :clear-text="search.length ? 'No search results found' : 'No Data yet'"
                            @sorter-change="onSort" @selected-items-change="(s) => (selectedTasks = s)"
                            @update-active-page="(value) => (activePage = value)"
                            @update-per-page="(value) => (perPage = value)">
                    <template #task_name_with_count="{ item }">
                        <COverdueTooltip v-if="item.is_overdue" :overdue-date="item.due_date"/>

                        <CButton color="link" size="sm" style="color: #0068ad" @click="goToView(item, false)">
                            <CIcon name="cilWindowRestore" @click.stop="goToView(item, true)"/>
                            {{ item.task_name_with_count }}
                        </CButton>
                    </template>

                    <template #clear_text>
                        <NoTasks :search="search"/>
                    </template>
                    <template #status="{ item, column }">
                        <TaskStatusBadge v-if="column.key === 'status'" :status="item.status"/>
                    </template>
                </SmartTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import UpdateTasks from '@/components/UpdateTasks'
import table from '@/mixin/table'
import GCFilter from '@/components/GCFilter/index'
import modalComponent from '@/mixin/modalComponent'
import {permissionError, updatePlan} from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import NoTasks from '@/components/SmartTable/partials/NoTasks.vue'
import onTimeoutSearch from '@/mixin/searchTimeout'
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";

export default {
    name: 'CompaniesList',
    inject: ['toast', 'mountedComponent'],
    mixins: [table, modalComponent, onTimeoutSearch],
    components: {
        TaskStatusBadge,
        NoTasks,
        CInputDateRange,
        CSelectSearch,
        UpdateTasks,
        GCFilter,
    },
    data() {
        return {
            exceptColumn: ['project_name', 'required_action'],
            tasks: [],
            fields: [
                {
                    key: 'task_name_with_count',
                    label: 'Task Name',
                    _style: '',
                },
                {key: 'status', label: 'Status', _style: ''},
                {
                    key: 'task_id',
                    label: 'Task ID',
                    _style: '',
                },
                {
                    key: 'external_company_name',
                    label: 'Company',
                    _style: '',
                    link: {name: 'CompanyInfo', params: {id: 'external_company_id'}},
                },
                {key: 'due_date', label: 'Due Date', _style: ''},
                {key: 'type', label: 'Type', _style: ''},
                {key: 'priority', label: 'Priority', _style: ''},
                {
                    key: 'internal_assignee',
                    label: 'Internal Assignee',
                    _style: '',
                    link: {
                        name: 'CompanyUser',
                        params: {id: 'company_id', user_id: 'internal_assignee_id'},
                    },
                },
                {
                    key: 'external_assignee',
                    label: 'External Assignee',
                    _style: '',
                    link: {
                        name: 'CompanyUser',
                        params: {
                            id: 'external_company_id',
                            user_id: 'external_assignee_id',
                        },
                    },
                },
                {key: 'scope_name', label: 'Scope Name', _style: ''},
                {key: 'package', label: 'Contacts Folder', _style: '', sorter: false},
            ],
            status: 'all',
            selectedTasks: [],
            tempFilter: {},
            editTasks: false,
            modal: false,
        }
    },
    watch: {
        status() {
            this.fetchData()
        },
    },
    methods: {
        ...mapActions(['fetchTasks']),
        goToView(item, newTab = false) {
            let route = null
            if (item.type === 'Request documents') {
                route = {
                    name: 'TaskRequest',
                    params: {id: item.project_id, task_id: item.id},
                }
            } else if (item.type === 'Contracts') {
                route = {
                    name: 'TaskContracts',
                    params: {id: item.project_id, task_id: item.id},
                }
            } else if (item.type === 'Acknowledge') {
                route = {
                    name: 'TaskAcknowledge',
                    params: {id: item.project_id, task_id: item.id},
                }
            }
            if (newTab) {
                window.open(this.$router.resolve(route).href, '_blank')
            } else {
                this.$router.push(route)
            }
        },
        makeQuery(f = {}) {
            f = Object.assign(this.tempFilter, f)
            return {
                params: {
                    ...f,
                    per_page: this.perPage,
                    page: this.activePage,
                    sort_field: this.sort && this.sort.column,
                    sort_direction: this.sort && this.sort.state,
                    project_id: this.$route.params.id,
                },
            }
        },
        fetchData(filter = {}) {
            this.search = filter.search || this.$route.query.search || this.search
            this.loadingTable = true
            this.tasks = []
            this.selectedTasks = []
            this.fetchTasks(this.makeQuery(filter))
                .then((res) => {
                    this.key++
                    this.selectedTasks = []
                    this.tasks = res.data
                    this.pages = res.meta.last_page
                    this.loadingTable = false
                })
                .catch(() => {
                    this.loadingTable = false
                })
        },
        closeEdit() {
            this.editTasks = false
            this.fetchData()
        },
        async checkPermission() {
            const check = await Gate.can(
                'tabAction',
                'project',
                this.$route.params.id,
            )
            if (!check) {
                if (this.$store.getters.isSubPlan) this.$notify(updatePlan)
                if (this.$store.getters.isGeneralPlan) this.$notify(permissionError)
            }
            return check
        },
        async handleEditTasks() {
            if (await this.checkPermission()) {
                this.editTasks = true
            }
        },
        async eventRevokeTasks() {
            const check = await this.checkPermission()
            if (!check) return false

            this.$modal
                .open({
                    close: 'Close',
                    success: 'Revoke',
                    label: 'Do you want to revoke selected tasks?',
                })
                .then(() => {
                    this.eventRevokeTasks()
                    this.$http.tasks
                        .editTasksMass({
                            task_id: this.selectedTasks,
                            status: 'Revoked',
                        })
                        .then(() => {
                            this.toast('success', 'The tasks have been revoked successfully')
                            this.$modal.close()
                            this.selectedTasks = []
                            this.fetchData()
                        })
                        .catch(({response}) => {
                            if (response.data.errors?.status?.length) {
                                this.toast('error', response.data.errors.status[0])
                                return
                            }
                            this.errors = response.data.errors
                                ? this.getErrors(response.data.errors)
                                : {}
                            this.toast('error', response.data.message)
                        })
                        .finally(() => this.$modal.close())
                })
        },
    },
}
</script>
<style lang="scss">
@import 'style';
</style>
<style lang="scss" scope>
.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}
</style>
